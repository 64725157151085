<template class="relative">
    <div class="">
    <div v-if="loading" id="testtest" class="absolute inset-0 z-50 flex justify-center items-center bg-surface-white bg-opacity-60">
        <svg class="w-10 h-w-10 rounded-full" viewBox="0 0 38 38" stroke="#fff">
          <g fill="none">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke="#E1E7EC" stroke-opacity=".5" cx="18" cy="18" r="18"/>
              <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
              </path>
            </g>
          </g>
        </svg>
    </div>

    <div class="min-h-screen bg-white flex">
        <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div class="mx-auto w-full max-w-sm lg:w-96">
                <div>
                    <img src="../../assets/svg/dark_mode_logo.svg" alt="logo" class="w-32 h-32" />
                    <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
                        Sign in to your account
                    </h2>
                </div>
                <div class="mt-8">
                    <div class="mt-6">
                        <form action="#" method="POST" class="space-y-6">
                            <div>
                                <label for="email" class="block text-sm font-medium text-gray-700">
                    Email address
                    </label>
                                <div class="mt-1">
                                    <input v-model="form.email" id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                </div>
                            </div>

                            <div class="space-y-1">
                                <label for="password" class="block text-sm font-medium text-gray-700">
                    Password
                    </label>
                                <div class="mt-1">
                                    <input v-model="form.password" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                </div>
                            </div>

                            <div>
                                <button @click.prevent="Login()" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Sign in
                    </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden lg:block relative w-0 flex-1">
            <img class="absolute inset-0 h-full w-full object-cover" src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80" alt="">
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        created() {
            this.SetRules();
        },
        data() {
            return {
                form: {
                    email: null,
                    password: null,
                },
                rules: null,
                errors: [],
                loading: false
            }
        },
        methods: {
            Login() {

                
                this.loading = true;
                this.$http.Login(this.form)
                    .then(res => {
                        this.loading = false
                        
                        let data = res.data;
                        if (data.statusCode === undefined || data.statusCode !== 0) {
                            this.$globalAlert.notify("تعذر الاتصال بالخادم، الرجاء المحاولة لاحقا", 'warning');
                            this.$authenticatedUser.userId = 0;
                            this.$authenticatedUser.fullName = '';
                            this.$authenticatedUser.userName = '';
                            this.$authenticatedUser.userPermissions = [];
                            // this.$parent.state = 1;
                            return;
                        }
                        this.$authenticatedUser.userId = data.result.userId;
                        this.$authenticatedUser.fullName = data.result.fullName;
                        this.$authenticatedUser.userName = data.result.userName;
                        this.$authenticatedUser.userPermissions = data.result.userPermissions;
                        //  this.$parent.state = 2;
                        this.$router.replace('/dashboard');


                    })
                    .catch(err => {
                        this.loading = false
                        if (err.response.data.statusCode !== null && err.response.data.statusCode !== undefined)
                            this.$globalAlert.alert(err.response.data.result + " [" + err.response.data.statusCode + "] ", 'error');
                        else this.$globalAlert.alert('تعذر الاتصال بالخادم، الرجاء المحاولة لاحقا', 'error');
                        this.$authenticatedUser.userId = 0;
                        this.$authenticatedUser.fullName = '';
                        this.$authenticatedUser.userName = '';
                        this.$authenticatedUser.userPermissions = [];
                        //  this.$parent.state = 1;
                    })

            },

            SetRules() {
                this.rules = {
                    //loginName: [
                    //    {
                    //        required: true,
                    //        validator: this.$validation.checkSelect(),
                    //        trigger: 'change'
                    //    }
                    //],
                    //password: [
                    //    {
                    //        required: true,
                    //        validator: this.$validation.checkInput(),
                    //        trigger: 'change'
                    //    }
                    //],
                };
            }

        }
    }
</script>