import axios from "axios";

export default {
    GetBanners(filter) {
        return axios.get(`/api/Posters/GetBanners`, {
            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
                searchByName: filter.searchByName,
            },
        });
    },
    AddBanner(bannerinfo) {
        return axios.post(`/api/Posters/AddBanner`, bannerinfo);
    },
    GetBannerForEdit(bannerId) {
        return axios.get(`/api/Posters/GetPosterForEdit?posterId=${bannerId}`);
    },
    EditBanner(bannerinfo) {
        return axios.put(`/api/Posters/EditBanner`, bannerinfo);
    },
    DeletePoster(bannerId) {
        return axios.delete(`/api/Posters/DeletePoster?posterId=${bannerId}`);
    },
    LockBanner(bannerId) {
        return axios.put(`/api/Posters/LockBanner?bannerId=${bannerId}`);
    },
    UnlockBanner(bannerId) {
        return axios.put(`/api/Posters/UnlockBanner?bannerId=${bannerId}`);
    },
};