<template>
    <main class="mb-16 px-4 max-w-sm md:max-w-3xl lg:max-w-6xl mx-auto">
        <div class="flex justify-between items-center my-10">
            <h2 class="uppercase text-lg md:text-2xl font-semibold text-gray-700 dark:text-gray-200">
                Dashboard
            </h2>
        </div>

        <div class="bg-white dark:bg-gray-800 px-12 pt-12 pb-24">
            <p class="font-medium text-base text-gray-900 dark:text-gray-200">Sales Summary</p>

            <div class="mt-7 grid grid-cols-2 gap-5">
                <div class="border border-gray-200 dark:border-gray-600 text-center py-8 col-span-2">
                    <p class="text-3xl text-gray-900 dark:text-gray-300 font-bold">{{ totalSales }}</p>
                    <p class="text-grey-H-600 text-base font-medium mt-3">TOTAL SALES</p>
                </div>
                <div class="border border-gray-200 dark:border-gray-600 text-center py-8">
                    <p class="text-3xl text-gray-900 dark:text-gray-300 font-bold">{{ companyRevenue }}</p>
                    <p class="text-grey-H-600 text-base font-medium mt-3">COMPANY REVENUE</p>
                </div>
                <div class="border border-gray-200 dark:border-gray-600 text-center py-8">
                    <p class="text-3xl text-gray-900 dark:text-gray-300 font-bold">{{ designerRevenue }}</p>
                    <p class="text-grey-H-600 text-base font-medium mt-3">DESIGNERS REVENUE</p>
                </div>
            </div>

            <p class="text-base text-grey-H-600 font-medium text-center mt-6">
                30% of the revenue is going for Hroof Charity *
            </p>

            <canvas class="mt-16" id="myChart" height="100"></canvas>
        </div>

        <div class="bg-white dark:bg-gray-800 px-12 pt-12">
            <p class="font-medium text-base text-grey-H-900 dark:text-gray-200">Most Selling Artworks</p>

            <div class="flex items-start mt-10">
                <span class="text-sm text-grey-H-500 font-bold w-5/12">Artwork</span>
                <span class="text-sm text-grey-H-500 font-bold w-2/12">Mediums</span>
                <span class="text-sm text-grey-H-500 font-bold w-2/12">Orders</span>
                <span class="text-sm text-grey-H-500 font-bold w-2/12">Revenue</span>
                <span class="text-sm text-grey-H-500 font-bold w-1/12"></span>
            </div>

            <div class="mt-4">
                <div v-for="(salesArtWork, index) in salesArtWorks" :key="index" class="flex items-center border-t border-grey-H-100 py-4">
                    <span class="text-sm font-medium w-5/12 flex space-x-4">
                        <img :src="salesArtWork.artWorkImage" alt="" class="w-16 h-12 object-cover">
                        <span class="flex flex-col">
                            <span class="text-grey-H-900 dark:text-gray-300">{{ salesArtWork.artworkName }}</span>
                            <span class="text-grey-H-600 text-xs mt-2">{{ salesArtWork.producerName }}</span>

                        </span>
                    </span>
                    <span class="text-sm text-grey-H-900 dark:text-gray-300 font-medium w-2/12">6</span>
                    <span class="text-sm text-grey-H-900 dark:text-gray-300 font-medium w-2/12">{{ salesArtWork.orders }}</span>
                    <span class="text-sm text-grey-H-900 dark:text-gray-300 font-medium w-2/12">{{ salesArtWork.revenue }}</span>
                    <span class="text-sm text-grey-H-700 font-bold text-right w-1/12">
                        <a href="#" class="underline">Deatails</a>
                    </span>
                </div>
            </div>

        </div>
    </main>
</template>

<script>
import Chart from 'chart.js'
export default {
  created() {},
  mounted() {
    this.GetStatistics();
    this.GetSalesArtWork();
    this.GetSales();

  },
  data() {
      return {
       sales: {},
      salesArtWorks: [],
      companyRevenue: null,
      designerRevenue: null,
      totalSales: null,
    };
  },
  methods: {
    GetStatistics() {
      this.loading = true;
      this.$http.dashboardService
        .GetStatistics()
        .then((res) => {
          this.data = res.data;
          this.companyRevenue = res.data.result.companyRevenue;
          this.designerRevenue = res.data.result.designerRevenue;
          this.totalSales = res.data.result.totalSales;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetSales() {
      this.loading = true;
      this.$http.dashboardService
        .GetSales()
        .then((res) => {
            this.data = res.data;
            this.sales = res.data.result.salesMonthly;
            this.loading = false;

            var ctx = document.getElementById('myChart').getContext('2d');
            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: [ this.sales[0].monthName, this.sales[1].monthName, this.sales[2].monthName, this.sales[3].monthName, 
                    this.sales[4].monthName, this.sales[5].monthName, this.sales[6].monthName, this.sales[7].monthName,
                     this.sales[8].monthName,this.sales[9].monthName, this.sales[10].monthName, this.sales[11].monthName],
                    datasets: [{
                        data: [
                            this.sales[0].amount,
                            this.sales[1].amount,
                            this.sales[2].amount,
                            this.sales[3].amount,
                            this.sales[4].amount,
                            this.sales[5].amount,
                            this.sales[6].amount,
                            this.sales[7].amount,
                            this.sales[8].amount,
                            this.sales[9].amount,
                            this.sales[10].amount,
                            this.sales[11].amount,
                        ],
                        backgroundColor: [
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                            'rgba(203, 233, 255, 0.2)',
                        
                        ],
                        borderColor: [
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                            'rgba(161, 212, 199, 1)',
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });

        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetSalesArtWork() {
      this.loading = true;
      this.$http.dashboardService
        .GetSalesArtWork()
        .then((res) => {
          this.data = res.data;
          this.salesArtWorks = res.data.result.salesArtWork;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
