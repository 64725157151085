import axios from 'axios';

export default {

    GetColors(filter) {
        return axios.get(`/api/Colors/GetColors`, {
            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
            }
        })
    },
    AddColor(ColorInfo) {
        return axios.post(`/api/Colors/AddColor`, ColorInfo)
    },
    DeleteColor(ColorId) {
        return axios.delete(`/api/Colors/DeleteColor/${ColorId}`);
    },

}