import axios from 'axios';

export default {

    GetReviews(filter) {
        return axios.get(`/api/Reviews/GetReviews`, {
            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
                searchByName: filter.searchByName,
            }
        })
    },
    DeleteReview(reviewId) {
        return axios.delete(`/api/Reviews/DeleteReview?reviewId=${reviewId}`);
    },



}