import axios from 'axios';

export default {

    GetProducers() {
        return axios.get('/api/Lookup/GetProducers')
    },
    GetCategories() {
        return axios.get('/api/Lookup/GetCategories')
    },
    GetColors() {
        return axios.get('/api/Lookup/GetColors')
    },
    GetSizes() {
        return axios.get('/api/Lookup/GetSizes')
    },
    GetArtworkGenre() {
        return axios.get('/api/Lookup/GetArtworkGenres')
    },
    GetProducts() {
        return axios.get('/api/Lookup/GetProducts')
    },
    GetArtworksByProducers(ProducerId) {
        return axios.get(`/api/Lookup/GetArtworksByProducers?ProducerId=${ProducerId}`);

    }

}