import axios from 'axios';

export default {

    GetArtworkGenre(filter) {
        return axios.get(`/api/ArtworkGenre/GetArtworkGenre`, {
            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
            }
        })
    },
    AddArtworkGenre(ArtworkGenreInfo) {
        return axios.post(`/api/ArtworkGenre/AddArtworkGenre`, ArtworkGenreInfo)
    },
    DeleteArtworkGenre(ArtworkGenreId) {
        return axios.delete(`/api/ArtworkGenre/DeleteArtworkGenre/${ArtworkGenreId}`);
    },

}