import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../features/dashboard/Dashboard.vue'
//import Layout from "../layout/Layout.vue";

Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'login',
        component: () =>
            import ('../features/login/Login.vue'),
        meta: { Dashboard: false, },
    },
    {
        path: "/dashboard",
        name: 'dashboard',
        component: Dashboard,
        meta: { Dashboard: true, },
    },
    {
        path: '/users',
        name: 'users',
        component: () =>
            import ('../features/users/Users.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/form',
        name: 'form',
        component: () =>
            import ('../features/form/form.vue'),
        meta: { Dashboard: true, },
    },

    // start products
    {
        path: '/dashboard/products',
        name: 'products',
        component: () =>
            import ('../features/products/the-products.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/products/create',
        name: 'product-create',
        component: () =>
            import ('../features/products/products-form.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/products/:product/edit',
        name: 'product-edit',
        component: () =>
            import ('../features/products/products-form.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/products/:product/productImages',
        name: 'Add-image',
        component: () =>
            import ('../features/products/productImages/Add-image.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/products/:product/producAttribut',
        name: 'add-Attribut',
        component: () =>
            import ('../features/products/attributes/attribut-form.vue'),
        meta: { Dashboard: true, },
    },
    // end products

    // start producers	
    {
        path: '/dashboard/producers',
        name: 'producers',
        component: () =>
            import ('../features/producers/the-producers.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/producers/create',
        name: 'producer-create',
        component: () =>
            import ('../features/producers/producers-form.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/producers/:producer/edit',
        name: 'producer-edit',
        component: () =>
            import ('../features/producers/producers-form.vue'),
        meta: { Dashboard: true, },
    },
    // end producers

    // start artwork	
    {
        path: '/dashboard/artworks',
        name: 'artworks',
        component: () =>
            import ('../features/artworks/the-artworks.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/artworks/create',
        name: 'artwork-create',
        component: () =>
            import ('../features/artworks/artworks-form.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/artworks/:artwork/edit',
        name: 'artwork-edit',
        component: () =>
            import ('../features/artworks/artworks-form.vue'),
        meta: { Dashboard: true, },
    },
    // end artwork

    // start categories
    {
        path: '/dashboard/categories',
        name: 'categories',
        component: () =>
            import ('../features/categories/the-categories.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/categories/create',
        name: 'category-create',
        component: () =>
            import ('../features/categories/categories-form.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/categories/:category/edit',
        name: 'category-edit',
        component: () =>
            import ('../features/categories/categories-form.vue'),
        meta: { Dashboard: true, },
    },

    //subcategories
    {
        path: '/dashboard/categories/:category/subcategories',
        name: 'view-subcategory',
        component: () =>
            import ('../features/categories/subcategories/the-sub-categories.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/categories/:category/subcategories/create',
        name: 'sub-category-create',
        component: () =>
            import ('../features/categories/subcategories/sub-categories-form.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/categories/:category/subcategories/:subcategory/edit',
        name: 'sub-category-edit',
        component: () =>
            import ('../features/categories/subcategories/sub-categories-form.vue'),
        meta: { Dashboard: true, },
    },
    // end categories

    // start banners
    {
        path: '/dashboard/banners',
        name: 'banners',
        component: () =>
            import ('../features/banners/the-banners.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/banners/create',
        name: 'banner-create',
        component: () =>
            import ('../features/banners/banners-form.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/banners/:banner/edit',
        name: 'banner-edit',
        component: () =>
            import ('../features/banners/banners-form.vue'),
        meta: { Dashboard: true, },
    },

    // start transactions	
    {
        path: '/dashboard/transactions',
        name: 'transactions',
        component: () =>
            import ('../features/transactions/the-transactions.vue'),
        meta: { Dashboard: true, },
    },
    // {	
    //     path: '/dashboard/transactions/:transaction/transaction-details',	
    //     name: 'transaction-details',	
    //     component: () =>	
    //         import ('../features/transactions/transactionDetails/transaction-details.vue'),	
    //     meta: { Dashboard: true, },	
    // },	
    // end transactions

    // start orders
    {
        path: '/dashboard/orders',
        name: 'orders',
        component: () =>
            import ('../features/orders/the-orders.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/orders/:order/order-details',
        name: 'order-details',
        component: () =>
            import ('../features/orders/orderDetails/order-details.vue'),
        meta: { Dashboard: true, },
    },
    // end orders
    // start reviews
    {
        path: '/dashboard/reviews',
        name: 'reviews',
        component: () =>
            import ('../features/productReviews/the-reviews.vue'),
        meta: { Dashboard: true, },
    }, // end reviews
    // start contentsMain
    {
        path: '/dashboard/contentsMain',
        name: 'contentsMain',
        component: () =>
            import ('../features/contentsMain/the-content.vue'),
        meta: { Dashboard: true, },
    },
    {
        path: '/dashboard/content/:content/edit',
        name: 'contentsMain-edit',
        component: () =>
            import ('../features/contentsMain/content-form.vue'),
        meta: { Dashboard: true, },
    },
    // end contentsMain


    // colors
    {
        path: '/dashboard/colors',
        name: 'colors',
        component: () =>
            import ('../features/colors/the-colors.vue'),
        meta: { Dashboard: true, },
    },

    // artwork-genre
    {
        path: '/dashboard/artwork-genre',
        name: 'artwork-genre',
        component: () =>
            import ('../features/artworkGenre/the-artwork-genre.vue'),
        meta: { Dashboard: true, },
    },

    {
        path: "*",
        redirect: "login"
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router