import axios from 'axios';

export default {
    //------------------------------- products ------------------------------//
    GetProducts(filter) {
        return axios.get(`/api/Products/GetProducts`, {
            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
                searchByName: filter.searchByName,
                categoryId: filter.categoryId,
                producerId: filter.producerId,
            }
        });
    },

    GetProductForView(productId) {
        return axios.get(`/api/Products/GetProductForView/${productId}`);
    },

    GetProductForEdit(productId) {
        return axios.get(`/api/Products/GetProductForEdit/${productId}`);
    },

    AddProduct(newProduct) {
        return axios.post(`/api/Products/AddProduct`, newProduct)
    },
    EditProduct(updateProduct) {
        return axios.put(`/api/Products/EditProduct`, updateProduct)
    },
    DeleteProduct(productId) {
        return axios.delete(`/api/Products/DeleteProduct/${productId}`);
    },
    PutSpecialProduct(productId, specialProduct) {
        return axios.get(`/api/Products/PutSpecialProduct`, {
            params: {
                productId: productId,
                specialProduct: specialProduct,
            }
        });
    },

    //------------------------------- Product Attributes ------------------------------//
    GetProductAttributes(filter, productId) {
        return axios.get(`/api/Products/GetProductAttributes`, {
            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
                productId: productId
            }
        });
    },

    AddProductAttributes(newAttribut) {
        return axios.post(`/api/Products/AddProductAttributes`, newAttribut)
    },
    multiProductAttributes(newAttributes) {
        return axios.post(`/api/Products/AddMultiProductAttributes`, newAttributes)

    },
    EditProductAttributes(updateAttributes) {
        return axios.put(`/api/Products/EditProductAttributes`, updateAttributes)
    },

    DeleteProductAttributes(productAttributeId) {
        return axios.delete(`/api/Products/DeleteProductAttributes/${productAttributeId}`);
    },
    //------------------------------- Product Images ------------------------------//
    GetProductImages(productId) {
        return axios.get(`/api/Products/GetProductImages`, {
            params: {
                productId: productId
            }
        });
    },
    AddProductImage(newImage) {
        return axios.post(`/api/Products/AddProductImage`, newImage)
    },

    DeleteProductImage(productImageId) {
        return axios.delete(`/api/Products/DeleteProductImage/${productImageId}`);
    },

    GetSizeList(sizeTypeId) {
        return axios.get(`/api/Products/GetSizeList?sizeType=${sizeTypeId}`)
    },

}