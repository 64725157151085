<template>
  <div class="dropdown w-1/3 lg:w-auto lg:inline-block relative">
    <div
      @click.prevent="Open(What_function)"
      class="dropdown-trigger border bor border-gray-300 dark:border-gray-600 rounded text-gray-500 text-sm hover:shadow focus:outline-none mx-1 my-1 lg:my-0"
    >
      <slot name="trigger"></slot>
    </div>
    <div
      :class="position"
      :id="id_dropdown"
      class="absolute mt-2 z-10 bg-gray-50 dark:bg-gray-700 pr-6 pt-6 pb-2 rounded hidden shadow-lg w-48"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ["id_dropdown", "What_function", "position"],
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    Open(x) {

      let specializationFilter = document.getElementById("specializationFilter");
      let clubFilter = document.getElementById("clubFilter");
      let coacheFilter = document.getElementById("coacheFilter");
      let genderFilter = document.getElementById("genderFilter");
      let bloodTypeFilter = document.getElementById("bloodTypeFilter");
      let nationalityFilter = document.getElementById("nationalityFilter");
      let beltDegreeFilter = document.getElementById("beltDegreeFilter");
      let categoryFilter = document.getElementById("categoryFilter");

      // Start specialization
        if (x == "openspecializationFilter") {
          let specializationFilterButton = document.querySelector( "#specializationFilterButton" );
          document.addEventListener( "click",
            function _listener(event) {
              if (
                event.target !== specializationFilterButton &&
                event.target !== specializationFilter &&
                !specializationFilter.contains(event.target)
              ) {
                if (specializationFilter.style.display === "block") {
                  specializationFilter.style.display = "none";
                }
                document.removeEventListener("click", _listener, true);
                return;
              }
            },
            true
          );


          if (specializationFilter.style.display === "block") {
            specializationFilter.style.display = "none";

          } else {
            specializationFilter.style.display = "block";
          }
        }
      // End specialization


      // Start gender
        if (x == "opengenderFilter") {
          let genderFilterButton = document.querySelector( "#genderFilterButton" );
          document.addEventListener( "click",
            function _listener(event) {
              if (
                event.target !== genderFilterButton &&
                event.target !== genderFilter &&
                !genderFilter.contains(event.target)
              ) {
                if (genderFilter.style.display === "block") {
                  genderFilter.style.display = "none";
                }
                document.removeEventListener("click", _listener, true);
                return;
              }
            },
            true
          );

          if (genderFilter.style.display === "block") {
            genderFilter.style.display = "none";

          } else {
            genderFilter.style.display = "block";
          }
        }
      // End gender


      // Start club
        if (x == "openclubFilter") {
          let clubFilterButton = document.querySelector( "#clubFilterButton" );
          document.addEventListener( "click",
            function _listener(event) {
              if (
                event.target !== clubFilterButton &&
                event.target !== clubFilter &&
                !clubFilter.contains(event.target)
              ) {
                if (clubFilter.style.display === "block") {
                  clubFilter.style.display = "none";
                }
                document.removeEventListener("click", _listener, true);
                return;
              }
            },
            true
          );

          if (clubFilter.style.display === "block") {
            clubFilter.style.display = "none";

          } else {
            clubFilter.style.display = "block";
          }
        }
      // End club


      // Start coache
        if (x == "opencoacheFilter") {
          let coacheFilterButton = document.querySelector( "#coacheFilterButton" );
          document.addEventListener( "click",
            function _listener(event) {
              if (
                event.target !== coacheFilterButton &&
                event.target !== coacheFilter &&
                !coacheFilter.contains(event.target)
              ) {
                if (coacheFilter.style.display === "block") {
                  coacheFilter.style.display = "none";
                }
                document.removeEventListener("click", _listener, true);
                return;
              }
            },
            true
          );

          if (coacheFilter.style.display === "block") {
            coacheFilter.style.display = "none";

          } else {
            coacheFilter.style.display = "block";
          }
        }
      // End coache


      // Start bloodType
        if (x == "openbloodTypeFilter") {
          let bloodTypeFilterButton = document.querySelector( "#bloodTypeFilterButton" );
          document.addEventListener( "click",
            function _listener(event) {
              if (
                event.target !== bloodTypeFilterButton &&
                event.target !== bloodTypeFilter &&
                !bloodTypeFilter.contains(event.target)
              ) {
                if (bloodTypeFilter.style.display === "block") {
                  bloodTypeFilter.style.display = "none";
                }
                document.removeEventListener("click", _listener, true);
                return;
              }
            },
            true
          );

          if (bloodTypeFilter.style.display === "block") {
            bloodTypeFilter.style.display = "none";

          } else {
            bloodTypeFilter.style.display = "block";
          }
        }
      // End bloodType


      // Start nationality
        if (x == "opennationalityFilter") {
          let nationalityFilterButton = document.querySelector( "#nationalityFilterButton" );
          document.addEventListener( "click",
            function _listener(event) {
              if (
                event.target !== nationalityFilterButton &&
                event.target !== nationalityFilter &&
                !nationalityFilter.contains(event.target)
              ) {
                if (nationalityFilter.style.display === "block") {
                  nationalityFilter.style.display = "none";
                }
                document.removeEventListener("click", _listener, true);
                return;
              }
            },
            true
          );

          if (nationalityFilter.style.display === "block") {
            nationalityFilter.style.display = "none";

          } else {
            nationalityFilter.style.display = "block";
          }
        }
      // End nationality


      // Start nationality
        if (x == "openbeltDegreeFilter") {
          let beltDegreeFilterButton = document.querySelector( "#beltDegreeFilterButton" );
          document.addEventListener( "click",
            function _listener(event) {
              if (
                event.target !== beltDegreeFilterButton &&
                event.target !== beltDegreeFilter &&
                !beltDegreeFilter.contains(event.target)
              ) {
                if (beltDegreeFilter.style.display === "block") {
                  beltDegreeFilter.style.display = "none";
                }
                document.removeEventListener("click", _listener, true);
                return;
              }
            },
            true
          );

          if (beltDegreeFilter.style.display === "block") {
            beltDegreeFilter.style.display = "none";

          } else {
            beltDegreeFilter.style.display = "block";
          }
        }
      // End beltDegree


      // Start nationality
        if (x == "opencategoryFilter") {
          let categoryFilterButton = document.querySelector( "#categoryFilterButton" );
          document.addEventListener( "click",
            function _listener(event) {
              if (
                event.target !== categoryFilterButton &&
                event.target !== categoryFilter &&
                !categoryFilter.contains(event.target)
              ) {
                if (categoryFilter.style.display === "block") {
                  categoryFilter.style.display = "none";
                }
                document.removeEventListener("click", _listener, true);
                return;
              }
            },
            true
          );

          if (categoryFilter.style.display === "block") {
            categoryFilter.style.display = "none";

          } else {
            categoryFilter.style.display = "block";
          }
        }
      // End category

    }
  }
};
</script>
