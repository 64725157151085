import axios from 'axios';

export default {

    GetStatistics() {
        return axios.get(`/api/Dashboard/GetStatistics`);      
    },
    GetSales() {
        return axios.get(`/api/Dashboard/GetSales`);      
    },
    GetSalesArtWork() {
        return axios.get(`/api/Dashboard/GetSalesArtWork`);      
    },
 
    

}