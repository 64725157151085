import Vue from "vue";
import Layout from "./layout/Layout.vue";
import DataService from "./services/DataService.js";
import router from "./router";
import VueRouter from "vue-router";
import "./assets/css/styles.css";
// import VueAxios from 'vue-axios';
// import axios from 'axios';
import "./shared/GlobalFilters";
// import Axios from "axios";

import Vuelidate from "vuelidate";
import { Pagination } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BlockUIService from "./services/BlockUIService";
import GlobalAlert from "./services/GlobalAlert";

// Axios.defaults.baseURL = "https://hroofstore.ly/managment";

Vue.use(Pagination);
Vue.use(VueRouter);
// Vue.use(axios);
Vue.use(Vuelidate);

Vue.component("dropdown", require("./partials/dropdown.vue").default);
Vue.component(
    "procedures-button",
    require("./partials/proceduresButton.vue").default
);
Vue.component("filter-button", require("./partials/filterButton.vue").default);

import "tailwindcss/tailwind.css";

Vue.config.productionTip = false;

Vue.prototype.$blockUI = BlockUIService;
Vue.prototype.$globalAlert = GlobalAlert;
Vue.prototype.$http = DataService;
Vue.prototype.$authenticatedUser = {
    userId: 0,
    fullName: "",
    userName: "",
    userPermissions: [],
};

new Vue({
    router,
    render: (h) => h(Layout),
}).$mount("#app");