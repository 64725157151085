import axios from 'axios';

export default {
    //------------------------------- Transactions ------------------------------//	
    GetTransactions(filter) {
        return axios.get(`/api/Transactions/GetTransactions`, {

            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
                productId: filter.filterTheProductArray,
                producerId: filter.filterTheProducerArray,
                startDate: filter.from,
                endDate: filter.to,
                searchByName: filter.searchByName,
            }
        })
    },
    GetTransactionForView(transactionId) {
        return axios.get(`/api/Transactions/GetTransactionForView/${transactionId}`);
    },


}