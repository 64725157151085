import axios from 'axios';

export default {
    ////-------------------   Session -------------------//
    //lastRequestOn: new Date(),

    //init() {
    //    axios.interceptors.request.use((config) => {
    //        this.lastRequestOn = new Date();
    //        return config;
    //    });
    //},
    //ResetSession() {
    //    return axios.get(`/Security/ResetSession`);
    //},

    //-------------------   Security -------------------//
    Login(login) {
        return axios.post('/api/Security/Login', login);
    },

    Logout() {
        return axios.post('/api/Security/Logout');
    },
    CheckLogin() {
        return axios.get('/api/Security/CheckLogin');
    },
}