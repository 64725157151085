<template>
  <header class="py-4 bg-black shadow-md-reverse dark:bg-gray-800">
    <div
      class="container flex items-center justify-between h-full px-6 mx-auto text-white dark:text-purple-300"
    >
      <!-- Mobile hamburger -->
      <button
        class="p-1 -mr-1 rounded-md xl:hidden focus:outline-none focus:shadow-outline-purple"
        @click="toggleSideMenu"
        aria-label="Menu"
      >
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <!-- Search input -->
      <div class="flex justify-center flex-1  lg:ml-32"></div>
      <!-- Theme toggler  +  Notifications menu  +  Profile menu -->
      <ul class="flex items-center justify-between w-32 flex-shrink-0 xl:ml-16">
        <!-- Theme toggler -->
        <li class="flex"> 
          <button
            :title="dark ? 'Dark mode' : 'Light mode'"
            class="rounded-md focus:outline-none focus:shadow-outline-purple"
            @click="toggleTheme"
            aria-label="Toggle color mode"
          >
            <template v-if="!dark">
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                ></path>
              </svg>
            </template>
            <template v-if="dark">
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </template>
          </button>
        </li>

        <li class="flex">
          <a  title="View website" href="#" class="">
            <svg
              class="w-5 h-5 fill-current"
              fill="none"
              stroke="currentColor"
              aria-hidden="true"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0"
              enable-background="new 0 0 24 24"
              id="Capa_1"
              viewBox="0 0 512 512"
            >
              <g>
                <path
                  d="m425.361 447.5h-377.861c-17.921 0-32.5-14.579-32.5-32.5v-260.5h331c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-331v-42.5c0-17.921 14.579-32.5 32.5-32.5h33.309c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-33.309c-26.191 0-47.5 21.309-47.5 47.5v318c0 26.191 21.309 47.5 47.5 47.5h377.861c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5z"
                />
                <path
                  d="m464.5 49.5h-348.691c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h348.691c17.921 0 32.5 14.579 32.5 32.5v42.5h-116c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h116v260.5c0 17.921-14.579 32.5-32.5 32.5h-4.691c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h4.691c26.191 0 47.5-21.309 47.5-47.5v-318c0-26.191-21.309-47.5-47.5-47.5z"
                />
                <path
                  d="m30.75 101.75c0 12.406 10.094 22.5 22.5 22.5s22.5-10.094 22.5-22.5-10.094-22.5-22.5-22.5-22.5 10.094-22.5 22.5zm30 0c0 4.136-3.364 7.5-7.5 7.5s-7.5-3.364-7.5-7.5 3.364-7.5 7.5-7.5 7.5 3.364 7.5 7.5z"
                />
                <path
                  d="m117.5 124.5c12.406 0 22.5-10.094 22.5-22.5s-10.094-22.5-22.5-22.5-22.5 10.094-22.5 22.5 10.094 22.5 22.5 22.5zm0-30c4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5-7.5-3.364-7.5-7.5 3.364-7.5 7.5-7.5z"
                />
                <path
                  d="m204 102c0-12.406-10.094-22.5-22.5-22.5s-22.5 10.094-22.5 22.5 10.094 22.5 22.5 22.5 22.5-10.094 22.5-22.5zm-30 0c0-4.136 3.364-7.5 7.5-7.5s7.5 3.364 7.5 7.5-3.364 7.5-7.5 7.5-7.5-3.364-7.5-7.5z"
                />
                <path
                  d="m459.5 124.5c12.406 0 22.5-10.094 22.5-22.5s-10.094-22.5-22.5-22.5h-213c-12.406 0-22.5 10.094-22.5 22.5s10.094 22.5 22.5 22.5zm-220.5-22.5c0-4.136 3.364-7.5 7.5-7.5h213c4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5h-213c-4.136 0-7.5-3.364-7.5-7.5z"
                />
                <path
                  d="m455.309 206.904h-143c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h143c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"
                />
                <path
                  d="m455.309 246.904h-143c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h143c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"
                />
                <path
                  d="m462.809 294.404c0-4.143-3.357-7.5-7.5-7.5h-143c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h143c4.142 0 7.5-3.357 7.5-7.5z"
                />
                <path
                  d="m178 418.404h156c19.575 0 35.5-15.925 35.5-35.5s-15.925-35.5-35.5-35.5h-156c-19.575 0-35.5 15.925-35.5 35.5s15.925 35.5 35.5 35.5zm0-56h156c11.304 0 20.5 9.196 20.5 20.5s-9.196 20.5-20.5 20.5h-156c-11.304 0-20.5-9.196-20.5-20.5s9.196-20.5 20.5-20.5z"
                />
                <path
                  d="m196.309 190.404h-110c-20.678 0-37.5 16.822-37.5 37.5v53c0 20.678 16.822 37.5 37.5 37.5h110c20.678 0 37.5-16.822 37.5-37.5v-2.054c0-.001 0-.003 0-.005v-50.94c0-20.678-16.823-37.501-37.5-37.501zm-110.123 112.997 13.231-20.337c2.259-3.472 1.275-8.117-2.197-10.377-3.472-2.258-8.118-1.275-10.377 2.197l-15.074 23.17c-4.865-4.13-7.96-10.285-7.96-17.151v-53c0-12.406 10.094-22.5 22.5-22.5h110c12.406 0 22.5 10.094 22.5 22.5v30.358l-22.749-27.171c-1.4-1.673-3.459-2.652-5.64-2.685-2.161-.073-4.269.887-5.717 2.517l-25.478 28.662-24.587-38.701c-1.368-2.154-3.737-3.465-6.289-3.479-.014 0-.027 0-.041 0-2.536 0-4.902 1.282-6.286 3.41l-16.06 24.686c-2.26 3.472-1.276 8.117 2.196 10.376 3.471 2.26 8.118 1.275 10.376-2.196l9.699-14.907 41.061 64.632h-82.985c-.041-.001-.082-.004-.123-.004zm110.123.003h-9.245l-19.474-30.653 22.548-25.366 28.652 34.224c-.373 12.08-10.311 21.795-22.481 21.795z"
                />
              </g>
            </svg>
          </a>
        </li>

        <!-- logout -->
        <li class="flex">
          <button title="Logout" @click.prevent="logout" class="">
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 512.00533 512"
            >
              <path
                d="m320 277.335938c-11.796875 0-21.332031 9.558593-21.332031 21.332031v85.335937c0 11.753906-9.558594 21.332032-21.335938 21.332032h-64v-320c0-18.21875-11.605469-34.496094-29.054687-40.554688l-6.316406-2.113281h99.371093c11.777344 0 21.335938 9.578125 21.335938 21.335937v64c0 11.773438 9.535156 21.332032 21.332031 21.332032s21.332031-9.558594 21.332031-21.332032v-64c0-35.285156-28.714843-63.99999975-64-63.99999975h-229.332031c-.8125 0-1.492188.36328175-2.28125.46874975-1.027344-.085937-2.007812-.46874975-3.050781-.46874975-23.53125 0-42.667969 19.13281275-42.667969 42.66406275v384c0 18.21875 11.605469 34.496093 29.054688 40.554687l128.386718 42.796875c4.351563 1.34375 8.679688 1.984375 13.226563 1.984375 23.53125 0 42.664062-19.136718 42.664062-42.667968v-21.332032h64c35.285157 0 64-28.714844 64-64v-85.335937c0-11.773438-9.535156-21.332031-21.332031-21.332031zm0 0"
              />
              <path
                d="m505.75 198.253906-85.335938-85.332031c-6.097656-6.101563-15.273437-7.9375-23.25-4.632813-7.957031 3.308594-13.164062 11.09375-13.164062 19.714844v64h-85.332031c-11.777344 0-21.335938 9.554688-21.335938 21.332032 0 11.777343 9.558594 21.332031 21.335938 21.332031h85.332031v64c0 8.621093 5.207031 16.40625 13.164062 19.714843 7.976563 3.304688 17.152344 1.46875 23.25-4.628906l85.335938-85.335937c8.339844-8.339844 8.339844-21.824219 0-30.164063zm0 0"
              />
            </svg>
          </button>
        </li>

        
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: "headerTemplate",
  data() {
    return {
      isSideMenuOpen: false,
      dark: false,
      isNotificationsMenuOpen: false,
      isProfileMenuOpen: false,
    };
  },
  methods: {
    toggleSideMenu() {
      this.isSideMenuOpen = !this.isSideMenuOpen;
      this.$emit("toisSideMenuOpen", this.isSideMenuOpen);
    },

    toggleTheme() {
      if (localStorage.theme === "dark") {
        document.querySelector("html").classList.remove("dark");
        localStorage.theme = "light";
        this.dark = false;
      } else {
        document.querySelector("html").classList.add("dark");
        localStorage.theme = "dark";
        this.dark = true;
      }
    },

    toggleNotificationsMenu() {
      this.isNotificationsMenuOpen = !this.isNotificationsMenuOpen;
    },
    closeNotificationsMenu() {
      this.isNotificationsMenuOpen = false;
    },

    toggleProfileMenu() {
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    },
    closeProfileMenu() {
      this.isProfileMenuOpen = false;
    },
    logout() {
      this.$parent.Logout();
    },
    //logout() {
    //    axios.post('/api/logout').then(() => {
    //        this.$router.push({ name: "home" })
    //    })
    //}
  },
};
</script>

<style></style>
