import { MessageBox, Notification } from 'element-ui';

export default (function () {
    return {

        notify(massage, TypeNotify) {
            var Title = (TypeNotify == "error" ? "تحذير" : "تنويه")
            Notification({
                title: Title,
                message: massage,
                type: TypeNotify,
                position: 'bottom-right'
            });

        },

        alert(message, TypeNotify) {
            MessageBox.alert(message, {
                confirmButtonText: 'موافق',
                type: TypeNotify,
            });
        },
    };
})();





