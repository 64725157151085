import axios from 'axios';

export default {
  
    AddCategory(Category) {
         return axios.post(`/api/Categories/AddCategoy`, Category)
    },
    GetCategoryForEdit(categoryId) {
        return axios.get(`/api/Categories/GetCategoyForEdit?categoryId=${categoryId}`);
    },
    EditCategory(Categoryinfo) {
        return axios.put(`/api/Categories/EditCategory`,Categoryinfo)
    },
    deleteCategory(CategoryId) {
        return axios.delete(`/api/Categories/deleteCategory?CategoryId=${CategoryId}`);
    },
    LockCategory(CategoryId) {
        return axios.put(`/api/Categories/LockCategory?CategoryId=${CategoryId}`);
    },
    UnlockCategory(CategoryId) {
        return axios.put(`/api/Categories/UnlockCategory?CategoryId=${CategoryId}`);
    },
    GetCategories(pageNo,pageSize,categoyName) {
        return axios.get(`/api/Categories/GetCategories?pageNo=${pageNo}&pageSize=${pageSize}&categoyName=${categoyName}`)
    },
    /// sub category
    AddSubCategory(SubCategoy) {
        return axios.post(`/api/Categories/AddSubCategoy`, SubCategoy)
     },
    GetSubCategoryForEdit(categoryId) {
         return axios.get(`/api/Categories/GetSubCategoyForEdit?categoryId=${categoryId}`);
     },
     EditSubCategory(Categoryinfo) {
         return axios.put(`/api/Categories/EditSubCategory`,Categoryinfo)
    },
    deleteSubCategory(CategoryId,ParentId) {
     return axios.delete(`/api/Categories/deleteSubCategory?CategoryId=${CategoryId}&ParentId=${ParentId}`);
     },
    GetSubCategories(pageNo,pageSize,ParentId,categoyName) {
         return axios.get(`/api/Categories/GetSubCategories?pageNo=${pageNo}&pageSize=${pageSize}&ParentId=${ParentId}&categoyName=${categoyName}`)
     },
}




