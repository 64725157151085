import axios from 'axios';

export default {

    GetContents() {
        return axios.get(`/api/Content/GetContents`);
    },
 
    GetContentForEdit(contentId) {
        return axios.get(`/api/Content/GetContentForEdit?contentId=${contentId}`);
    },
    EditContent(content) {
        return  axios.put(`/api/Content/EditContent?content`,content)

     }


}