import axios from 'axios';

export default {

    GetArtworks(filter) {
        return axios.get(`/api/Artworks/GetArtworks`, {
            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
                searchByName: filter.searchByName,
            }
        })
    },
    AddArtwork(Artworkinfo) {
        return axios.post(`/api/Artworks/AddArtwork`, Artworkinfo)
    },
    GetArtworkForEdit(artworkId) {
        return axios.get(`/api/Artworks/GetArtworkForEdit?artworkId=${artworkId}`);
    },
    EditArtwork(Artworkinfo) {
        return axios.put(`/api/Artworks/EditArtwork`, Artworkinfo)
    },
    DeleteArtwork(ArtworkId) {
        return axios.delete(`/api/Artworks/DeleteArtwork/${ArtworkId}`);
    },
    LockArtwork(ArtworkId) {
        return axios.put(`/api/Artworks/LockArtwork?ArtworkId=${ArtworkId}`);
    },
    UnlockArtwork(ArtworkId) {
        return axios.put(`/api/Artworks/UnlockArtwork?ArtworkId=${ArtworkId}`);
    },


}