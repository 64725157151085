import axios from 'axios';

export default {
    //------------------------------- products ------------------------------//
    GetOrders(pageSize, pageNo, payMethod, statusId, orderCode, searchByName) {
        return axios.get(`/api/Orders/GetOrders`, {
            params: {
                pageNo: pageNo,
                pageSize: pageSize,
                orderCode: orderCode,
                payMethod: payMethod,
                statusId: statusId,
                searchByName: searchByName,
            }
        });
    },

    GetOrderForView(orderId) {
        return axios.get(`/api/Orders/GetOrderForView/${orderId}`);
    },

    MakeAsPaid(orderId) {
        return axios.get(`/api/Orders/MakeAsPaid?${orderId}`);
    },

    updateStatus(orderId, statusId) {
        return axios.post(`/api/Orders/UpdateStatus?orderId=${orderId}&statusId=${statusId}`)
    },
    DeleteOrder(orderId) {
        return axios.delete(`/api/Orders/DeleteOrder/${orderId}`);
    },

    //------------------------------- OrderDetails ------------------------------//



}