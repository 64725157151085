import axios from 'axios';

export default {

    GetProducers(filter) {
        return axios.get(`/api/Poducers/GetProducers`, {
            params: {
                pageNo: filter.pageNo,
                pageSize: filter.pageSize,
                searchByName: filter.searchByName,
            }
        });
    },
    Addproducer(producerinfo) {
        return axios.post(`/api/Poducers/Addproducer`, producerinfo)
    },
    GetproducerForEdit(producerId) {
        return axios.get(`/api/Poducers/GetproducerForEdit?producerId=${producerId}`);
    },
    Editproducer(producerinfo) {
        return axios.put(`/api/Poducers/Editproducer`, producerinfo)
    },
    Deleteproducer(producerId) {
        return axios.delete(`/api/Poducers/Deleteproducer?producerId=${producerId}`);
    },
    Lockproducer(producerId) {
        return axios.put(`/api/Poducers/Lockproducer?producerId=${producerId}`);
    },
    Unlockproducer(producerId) {
        return axios.put(`/api/Poducers/Unlockproducer?producerId=${producerId}`);
    },


}