//import axios from 'axios';
import securityService from './SecurityService.js';
import CategoriesService from './CategoriesService.js';
import BannersService from './BannersService.js';
import ProductsService from './ProductsService.js';
import OrdersService from './OrdersService.js';
import LookupService from './LookupService.js';
import ProducersService from './ProducersService.js';
import ArtworksService from './ArtworksService.js';
import TransactionsService from './TransactionsService.js';
import ReviewsService from './ReviewsService.js';
import ContentsMainsService from './ContentsMainsService.js';
import DashboardService from './DashboardService.js';
import ColorsService from './ColorsService.js';
import ArtworkGenreService from './ArtworkGenreService.js';


export default {

    ...securityService,
    categoriesService: CategoriesService,
    bannersService: BannersService,
    productsService: ProductsService,
    lookupService: LookupService,
    ordersService: OrdersService,
    transactionsService: TransactionsService,
    artworksService: ArtworksService,
    producersService: ProducersService,
    reviewsService: ReviewsService,
    contentsMainsService: ContentsMainsService,
    dashboardService: DashboardService,
    colorsService: ColorsService,
    artworkGenreService: ArtworkGenreService
}