import Vue from "vue";

Vue.filter("dateTime", function(myDateString) {
    if (myDateString === null) return "-";
    return myDateString.slice(0, 19).replace(/T/g, " ");
});

Vue.filter("date", function(myDateString) {
    if (myDateString === null) return "-";
    return myDateString.slice(0, 10);
});

Vue.filter("statusToString", function(myStatus) {
    if (myStatus === null) return "-";
    let status = "";
    if (myStatus === 0) status = "مدخل";
    else if (myStatus === 1) status = "مفعل";
    else if (myStatus === 2) status = "مجمد";
    else if (myStatus === 5) status = "معتمد";
    else if (myStatus === 6) status = "مرفوض";
    else if (myStatus === 7) status = "مرحل";
    else if (myStatus === 8) status = "ملغي";
    else status = "-";
    return status;
});

Vue.filter("nullData", function(data) {
    if (data === "" || data === null) return "لا يوجد";
    else return data;
});

Vue.filter("null", function(data) {
    if (data === "" || data === null) return "-";
    else return data;
});

Vue.filter("message", function(data) {
    if (data === "" || data === null) return "-";
    else return data;
});

Vue.filter("orderstatus", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 0) statusName = "Pending";
    else if (status === 1) statusName = "paid";
    else if (status === 2) statusName = "Process";
    else if (status === 3) statusName = "Cancel";
    else if (status === 4) statusName = "Completed";
    return statusName;
});

Vue.filter("orderStatusColor", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 0) statusName = "bg-yellow-100";
    else if (status === 1) statusName = "bg-orange-100";
    else if (status === 2) statusName = "bg-blue-100";
    else if (status === 3) statusName = "bg-red-100";
    else if (status === 4) statusName = "bg-green-100";
    return statusName;
});

Vue.filter("paymentMethod", function(payment) {
    if (payment === null) return "-";
    let paymentName = "";
    if (payment === 1) paymentName = "COD";
    else if (payment === 0) paymentName = "Sdad";
    return paymentName;

});