import headerTemplate from "../partials/header.vue";
import asideTemplate from "../partials/sidebar.vue";
import login from "../features/login/Login.vue";
export default {
    components: {
        headerTemplate,
        asideTemplate,
        login,
    },
    created() {
        this.CheckLogin();
    },
    data() {
        return {
            state: 0,

            admin: false,
            isSideMenuOpen: false,
        };
    },
    mounted() {
        if (
            localStorage.theme === "dark" ||
            (!("theme" in localStorage) &&
                window.matchMedia("(prefers-color-scheme: dark)").matches)
        ) {
            document.querySelector("html").classList.add("dark");
            localStorage.theme = "dark";
        } else {
            document.querySelector("html").classList.remove("dark");
            localStorage.theme = "light";
        }
    },
    methods: {
        toggleSideMenu() {
            this.isSideMenuOpen = !this.isSideMenuOpen;
        },
        toPassIsSideMenuOpen: function(isSideMenuOpen) {
            this.isSideMenuOpen = isSideMenuOpen;
        },
        CheckLogin() {
            this.loding = true;
            this.$http
                .CheckLogin()
                .then((res) => {
                    this.loding = false;
                    let data = res.data;
                    if (data.result) {
                        this.$authenticatedUser.userId = data.result.userId;
                        this.$authenticatedUser.fullName = data.result.fullName;
                        this.$authenticatedUser.userName = data.result.userName;
                        this.$authenticatedUser.userPermissions =
                            data.result.userPermissions;

                        if (!this.$route.meta.Dashboard) {
                            this.$router.replace("/dashboard");
                        }

                        //                        this.state = 2;
                    } else {
                        this.$authenticatedUser.userId = 0;
                        this.$authenticatedUser.fullName = "";
                        this.$authenticatedUser.userName = "";
                        this.$authenticatedUser.userPermissions = [];
                        if (this.$route.meta.Dashboard) {
                            this.$router.replace("/login");
                        }

                        // this.state = 1;
                    }
                })
                .catch(() => {
                    this.loding = false;
                    this.$authenticatedUser.userId = 0;
                    this.$authenticatedUser.fullName = "";
                    this.$authenticatedUser.userName = "";
                    this.$authenticatedUser.userPermissions = [];
                    // this.state = 1;
                    this.$router.replace("/login");
                });
        },
        Logout() {
            //this.$blockUI.Start();
            //   this.profile = false;
            //  this.loding = true;
            this.$http
                .Logout()
                .then((res) => {
                    let data = res.data;
                    if (data.statusCode === 0) {
                        this.$authenticatedUser.userId = 0;
                        this.$authenticatedUser.fullName = "";
                        this.$authenticatedUser.userName = "";
                        this.$authenticatedUser.userPermissions = [];
                        // this.state = 1;

                        this.$router.replace("/login");

                        //this.$blockUI.Stop();
                    } else {
                        //this.$blockUI.Stop();
                    }
                })
                .catch(() => {
                    //this.$blockUI.Stop();
                });
        },
    },
};